import React, {useState} from 'react';
import { graphql } from "gatsby";
import Heading from "../ui/heading";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import CarouselAboutOffice from '../components/Carousel/CarouselAboutOffice';
import MapBox from '../components/MapBox';
import FormBlock from "../components/Form";
import {ContainerCarousel, ContainerFluid, ContainerL} from '../ui/containers';
import Modal from "../ui/modal";
import Seo from "../components/seo";
import "../components/layout.css";


const Showroom = ({data}) => {
    const showroomData = data.allStrapiShowroom.nodes[0];
    const contactsData = data.allStrapiContacts.nodes[0];
    const [openModal, setOpenModal] = useState(false);

    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    }
    return (
        <div>
            <Seo title={"ШОУРУМ"} />
            <Header/>
            {
                openModal ?
                    (<Modal toggleOpenModal={toggleOpenModal}/>)
                    :
                    (
                        <>
                            <ContainerL style={{margin: "110px auto"}}>
                                <Heading title={"ШОУ"} boldTitle={"РУМ"}
                                         description={showroomData.description}
                                         button
                                         toggleOpenModal={toggleOpenModal}
                                         buttonText="РАСЧИТАТЬ СТОИМОСТЬ"
                                         background="ЖДЕМ ТЕБЯ"
                                />
                            </ContainerL>

                            <ContainerCarousel>
                              <CarouselAboutOffice contacts={contactsData} items={showroomData.carousel} />
                            </ContainerCarousel>


                            <ContainerFluid style={{margin: "120px auto"}}>
                                <MapBox />
                            </ContainerFluid>

                            <ContainerL style={{margin: "120px auto"}}>
                                <FormBlock />
                            </ContainerL>

                            <Footer toggleOpenModal={toggleOpenModal}/>
                        </>
                    )}


        </div>
    );
};

export default Showroom;

export const query = graphql`
    query ShowroomQuery {
        allStrapiShowroom {
            nodes {
                description
                carousel {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        allStrapiContacts {
            nodes {
                email
                adress
                phone
                schedule
            }
        }
    }

`
